@import "../../styles/mixins";
@import "../../styles/vars";


.app {
  height: 100%;
  background: $color-bg;
  width: 100%;
  overflow: hidden;

  &_mobile {
    height: calc(100% + 1px)
  }

  &_topGradient {
    @include bgWebp("../../assets/images/leagueBg.png","../../assets/images/leagueBg.webp");
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__bg {
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;

    &_active {
      animation: slide 5s infinite linear;
    }
  }

  &__marque {
    overflow: hidden;
  }

  &__starsImg {
    width: 100%;
  }

  &__container {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    padding-left: 20px;
    height: 100%;
    z-index: 1;
    position: relative;
    opacity: 1;
    transition: $transition;

    &_hidden {
      opacity: 0;
      transition: $transition;
    }

    &:not(&_full) {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  &__main {
    flex: 1;
    height: calc(100vh - 75px);
    width: 100%;
    
    &_visible {
      margin-bottom: -75px;
    }
  }
}

