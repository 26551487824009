@import "../../../styles/vars";
@import "../../../styles/mixins";


.earnList {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 10px;

  &__typeText {
    @include setFont(14px, 600, $font-fam-outfit);
    color: $color-white;
    margin-bottom: 4px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
