@import "../../../styles/vars";
@import "../../../styles/mixins";

.boostHeader {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 24px;

  &__coins {
    width: 100%;
    @include flexCol();
    gap: 16px;
  }

  &__coinItem {
    width: 100%;
    padding: 15px 0;
    @include flexCol();
    gap: 12px;
    background: $color-btn;
    border-radius: 12px;
  }

  &__coinImg {
    width: 12px;
  }

  &__coinTxt {
    @include setFont(14px, 400);
    color: $color-white;
  }

  &__title {
    padding-top: 24px;
    padding-bottom: 16px;
    text-align: center;
    @include setFont(16px, 400, $font-fam-outfit);
  }
}
