@import "../../../styles/vars";
@import "../../../styles/mixins";

.achievmentImageFrame {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  padding: 13px;
  @include flexCol();
  background: linear-gradient(180.75deg, #f58136 -10.01%, #272a2e 107.21%);
  margin-block: 6px;

  &__inner {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    @include flexCol();
    background: $color-black-bg;
  }
  &__img {
    width: 45px;
    height: 45px;
    object-fit: contain;
  }
}
