@import "../../../styles/vars";
@import "../../../styles/mixins";

.boostList {
  background: #34343a80;
  flex: 1;
  height: 100%;

  padding: 8px 0 100px;
  border-radius: 12px 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &__categories {
    width: 100%;
    min-height: 34px;
    overflow-x: auto;
  }

  &__categoriesList {
    display: flex;
    gap: 8px;
    width: fit-content;
    padding: 0 20px;
  }

  &__categoryItem {
    border: none;
    cursor: pointer;
    width: 68px;
    min-width: 68px;
    height: 34px;
    border-radius: 8px;
    overflow: hidden;
    background: transparent;
    transition: $transition;

    &_active {
      background: $color-yellow-gradient;
      padding: 1px;
    }
  }

  &__categoryItemInner {
    @include flexCol();
    height: 100%;
    width: 100%;
    @include setFont(12px, 400);
    color: $color-white;
    background: $color-btn;
    border-radius: 7px;
    transition: $transition;

    &_active {
      width: 100%;
      background:  $color-black-bg;;
    }
  }

  &__main {
    padding: 26px 20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }

  &__item {
    border-radius: 20px;
    background: $color-btn;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    &_blocked {
      background: #93939f80;
    }
  }

  &__itemMain {
    width: 100%;
    flex: 1;
    padding: 8px 10px 6px;

    border-bottom: 1px solid #65658b80;
  }

  &__itemMainWrapper {
    @include flexCol(flex-start,flex-start);
    gap: 4px;
  }

  &__item_blocked &__itemMain {
    border-color: #34343a80;
  }

  &__itemImgWrapper {
    width: 35%;
    aspect-ratio: 1/1;
    object-fit: contain;
    border-radius: 50%;
    position: relative;
  }

  &__itemImg {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    object-fit: contain;
  }

  &__itemImgBlockOverlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #ffffff33;
    backdrop-filter: blur(10px);
    @include flexCol();
  }
  &__itemBlockImg {
    width: 50%;
  }

  &__itemTexts {
    width: 70%;
  }

  &__nameTxt {
    @include setFont(14px, 500, $font-fam-sec);
    color: $color-white;
    padding-bottom: 8px;
    line-height: 16px;
    text-align: left;
  }

  &__descriptionTxt {
    @include setFont(10px, 500, $font-fam-sec);
    color: #98938e;
    padding-bottom: 2px;
    text-align: left;
    line-height: 12px;
  }

  &__item_blocked &__descriptionTxt {
    color: $color-grey-light;
  }

  &__perHoursCoins {
    @include flexCol(flex-start);
    gap: 4px;
  }

  &__perHoursCoinImg {
    width: 12px;
  }

  &__perHoursCoinsTxt {
    @include setFont(14px, 500, $font-fam-sec);
    color: $color-white;
    line-height: 15px;
  }
  &__item_blocked &__perHoursCoinsTxt {
    color: $color-grey-light;

  }

  &__itemFooter {
    @include flexCol(flex-start);
    gap: 4px;
    padding: 8px;
    width: 100%;
  }

  &__lvlBlock {
    width: 35%;
    padding: 6px;
  }

  &__lvlTxt {
    text-align: center;
    @include setFont(14px, 500, $font-fam-outfit);
    color: $color-white;
    border-right: 1px solid #65658b80;
    width: 100%;
  }
  &__item_blocked &__lvlTxt {
    color: $color-grey-light;
    border-color: #34343a80;
  }
  &__priceCoins {
    @include flexCol(flex-start);
    gap: 4px;
  }

  &__priceCoinImg {
    width: 15px;
  }

  &__priceCoinTxt {
    @include setFont(12px, 500, $font-fam-outfit);
    color: $color-white;
  }
  &__blockTxt {
    @include setFont(9px, 500, $font-fam-outfit);
    color: $color-white;
    text-align: left;
  }
}
