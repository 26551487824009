@import "../../../styles/vars";
@import "../../../styles/mixins";


.homeTotalScore {
  @include flexCol();
  gap: 12px;

  &__coinIcon {
    @include icon(37px);
  }

  &__txt {
    @include setFont(40px, 700, $font-fam-outfit);
    color: $color-white;
    text-decoration: none;

    &_small {
      font-size: 30px;
    }
  }

  &__arrowIcon {
    @include icon(18px)
  }

  &__modal {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 400px;
    @include bgWebp("../../../assets/images/rewardBg.png","../../../assets/images/rewardBg.webp");
  }

  &__modalMain {
    margin-top: 54px;
    background: $color-btn-active;
    border-radius: 12px;
    @include flexCol();
    flex-direction: column;
    padding: 0 18px 12px;
  }

  &__modalIcon {
    width: 64px;
    margin-top: -44px;
  }

  &__modalCoins {
    @include flexCol();
    gap: 12px;
    padding-top: 8px;
    padding-bottom: 18px;
  }

  &__modalCoinImg {
    width: 38px;
  }

  &__modalCoinText {
    @include setFont(40px, 700, $font-fam-outfit);
    color: $color-white;
  }

  &__modalMainText {
    @include setFont(24px, 400, $font-fam-outfit);
    color: $color-white;
    text-align: center;
  }
}


