@import "../../../styles/vars";
@import "../../../styles/mixins";


.leagueHeader {
  padding-top: 10px;

  &__minersBlock {
    border-radius: 12px;
    background: $color-btn;
    @include flexCol();
    padding: 8px 16px;
    gap: 8px;
    margin-bottom: 31px;
  }

  &__minersAvatars {
    @include flexCol(flex-start);
  }

  &__avaImg {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 2px solid #203152;
    margin-left: -4px;
  }

  &__minersText {
    flex: 1;
    text-align: center;
    @include setFont(14px, 600);
    color: $color-white;
  }

  &__slider {
    width: 100%;
    position: relative;
  }

  &__sliderArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    border: none;
    background: transparent;
    padding: 8px 16px;
    z-index: 999;

    &_right {
      right: 14px;
    }

    &_left {
      left: 14px;
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &__sliderArrowImg {
    width: 9px;
  }

  &__sliderItem {
    @include flexCol();
    flex-direction: column;
    gap: 10px;
    margin: 0 auto;
  }

  &__sliderImgWrapper {
    height: 130px;
    padding: 0 10px;
    border-radius: 12px;
    background: $color-btn;
    width: fit-content;
    margin: 0 auto;
    @include flexCol();
  }

  &__sliderImg {
    width: 80px;
    object-fit: contain;
  }

  &__nameTxt {
    @include setFont(32px, 600, $font-fam-sec);
    color: $color-white;
    padding: 10px 0;
    text-align: center;
  }

  &__coinsTxt {
    @include setFont(14px, 600);
    color: $color-white;
    padding-bottom: 8px;
    text-align: center;
  }

  &__progressBarWrapper {
    overflow: hidden;
    @include flexCol();
  }
}
