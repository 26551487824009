@import "../../styles/vars";
@import "../../styles/mixins";

.newAchievmentModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: $color-black-bg;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  z-index: 999;

  &__lottieWrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    @include flexCol();
    z-index: 0;
  }
  &__lottie {
    width: 80%;
  }

  &__main {
    flex: 1;
    @include flexCol();
    flex-direction: column;
  }

  &__sliderWrapper {
    width: 100%;
  }

  &__slide {
    @include flexCol;
    flex-direction: column;
  }
  &__name {
    @include setFont(24px, 400, $font-fam-outfit);
    color: $color-white;
    text-align: center;
  }
  &__arrowsBlock {
    @include flexCol(space-between);
    margin-bottom: 31px;
    width: 100%;
  }
  &__arrowBtn {
    border: none;
    background: transparent;

    &_prev {
      cursor: pointer;
    }

    &_next {
      cursor: pointer;
    }

    &_hidden {
      display: none;
    }
  }
  &__arrowIcon {
    width: 9px;
    height: 18px;

    &_prev {
      transform: rotate(180deg);
    }
  }

  &__subtitle {
    @include setFont(18px, 400, $font-fam-outfit);
    color: $color-white;
    margin: 0 auto;
    text-align: center;
  }
  &__percentBlock {
    width: 100%;
    border-radius: 12px;
    background-color: $color-grey-bg;
    max-width: 269px;
    padding-block: 18px;
    @include flexCol();
    flex-direction: column;
  }
  &__percentTxt {
    @extend %yellow-gradient-text;
    @include setFont(24px, 800, $font-fam-outfit);
    margin-bottom: 8px;
  }
  &__percentSubTxt {
    @include setFont(12px, 400, $font-fam-outfit);
    color: $color-white;
  }
  &__btn {
    z-index: 2;
    position: relative;
  }
}
