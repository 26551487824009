@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.achievmentModal {
  background: $color-black-bg;
  border-top: 1px solid $color-yellow-bright;

  &__container {
    padding: 18px 18px 30px;
    @include flexCol();
    flex-direction: column;
  }
  &__crossBtn {
    align-self: flex-end;
    cursor: pointer;
    border: none;
    background: transparent;
  }
  &__crossIconImg {
    width: 28px;
    height: 28px;
  }
  &__title {
    @include setFont(24px, 400, $font-fam-outfit);
    color: $color-white;
    margin-top: 10px;
    text-align: center;
  }
  &__recievedTxt {
    @include setFont(18px, 400, $font-fam-outfit);
    color: $color-white;
  }
  &__info {
    margin-block: 40px;
    background-color: $color-grey-bg;
    padding: 10px 12px;
    width: 100%;
    border-radius: 12px;
    position: relative;
    display: flex;
    justify-content: flex-start;
  }
  &__infoTxtBlock {
    background: $color-black-bg;
    border-radius: 12px;
    padding: 8px;
    @include flexCol();
    flex-direction: column;
    gap: 8px;
    max-width: 132px;
  }
  &__infoPercentTxt {
    @include setFont(24px, 400, $font-fam-outfit);
    @extend %yellow-gradient-text;
  }
  &__InfoUsersTxt {
    @include setFont(12px, 400, $font-fam-outfit);
    color: $color-white;
    text-align: center;
  }
  &__infoImg {
    width: 140px;
    height: 140px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
}
