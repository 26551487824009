@import "../../styles/vars";
@import "../../styles/mixins";


.bottomNavbar {
  background: rgba(53, 53, 57, 0.5);
  border-radius: 16px;
  @include flexCol();
  padding: 5px;

  &_light {
    background:$color-btn-active;
  }

  & * {
    @extend %no-user-select;
  }

  &__item {
    @include flexCol();
    max-width: 76px;
    width: 100%;
    height: 100%;

    &:last-child .bottomNavbar__border {
      display: none;
    }
  }


  &__itemLink {
    width: 100%;
    @include flexCol();
    flex-direction: column;
    gap: 1px;
    border-radius: 4px;
    background: transparent;
    transition: $transition;
    text-decoration: none;
    filter: grayscale(1);
    padding-top: 6px;
    padding-bottom: 6px;
    margin-right: 3px;
    margin-left: 3px;


    &_active {
      background: rgba(21, 31, 43, 1);
      filter: none;
    }

  }


  &__itemImg {
    width: 34px;
    aspect-ratio: 1/1;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    &_moon {
      @include bgWebp("../../assets/images/moon.png","../../assets/images/moon.webp")
    }

    &_base {
      @include bgWebp("../../assets/images/base.png","../../assets/images/base.webp")
    }

    &_frens {
      @include bgWebp("../../assets/images/frens.png","../../assets/images/frens.webp")
    }

    &_earn {
      @include bgWebp("../../assets/images/earn.png","../../assets/images/earn.webp")
    }

    &_boost {
      @include bgWebp("../../assets/images/boost.png","../../assets/images/boost.webp")
    }
  }


  &__itemTxt {
    @include setFont(12px, 500);
    color: $color-grey;
  }

  &__border {
    height: calc(100% - 12px);
    margin: 6px 0;
    border-right: 1px solid $color-grey;
  }
}
