@import "vars";


@mixin icon($width,$height: $width,) {
  width: $width;
  height: $height;
  object-fit: contain;
}

@mixin setFont($size,$weight,$family: $font-fam-main) {
  font-size: $size;
  font-weight: $weight;
  font-family: $family;
}

@mixin flexCol($layout: center,$align: center) {
  display: flex;
  justify-content: $layout;
  align-items: $align;
}

@mixin bgWebp($src,$srcSet) {
    background-image: url($src); /* Fallback image */

  @supports (background-image: url($srcSet)) {
      background-image: url($srcSet);
  }
}


%no-user-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}


%yellow-gradient-text {
  background: $color-yellow-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}