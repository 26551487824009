@import "../../../styles/vars";
@import "../../../styles/mixins";

.customModal {
  background: #16202C !important;
  border-top: 1px solid $color-btn-active;

  &__header {
    background: transparent !important;

    &::before {
      display: none;
    }
  }

  &__container {
    padding: 20px;
  }
}