@import "../../../styles/vars";
@import "../../../styles/mixins";


.frensInfoBlock {
  background: $color-btn;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 8px;
  @include flexCol();
  flex-direction: column;
  gap: 8px;


  &__main {
    @include flexCol();
    gap: 6px;
    width: 100%;
  }

  &__mainItem {
    flex: 1;
    @include flexCol();
    flex-direction: column;
    gap: 8px;

    &:nth-child(2) {
      flex: 1.5;
    }
  }

  &__iconImg {
    width: 32px;
    aspect-ratio: 1/1;
    object-fit: contain;
  }

  &__nameTxt {
    @include setFont(12px, 600);
    color: $color-text-main;
    text-align: center;
  }

  &__coinsTxt {
    @include setFont(12px, 600);
    @extend %yellow-gradient-text
  }

  &__title {
    @include setFont(16px, 400, $font-fam-outfit);
    color: $color-white;
  }
}
