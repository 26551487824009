@import "../../../styles/vars";
@import "../../../styles/mixins";


.mainBtn {
  width: 100%;
  @include flexCol();
  gap: 18px;
  padding: 16px;
  background: $color-yellow-gradient;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  @include setFont(16px, 600, $font-fam-outfit);
  color: $color-white;

  &:disabled {
    background: $color-grey;
  }
}