@import "../../../styles/vars";
@import "../../../styles/mixins";


.homeEnergy {
  margin-bottom: 34px;
  width: 100%;


  &__info {
    @include flexCol(space-between);
    margin-bottom: 10px;
  }

  &__icon {
    @include icon(20px)
  }

  &__txt {
    @include setFont(14px, 400);
    color: $color-white;
  }

}
