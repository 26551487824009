@import "vars";
@import "mixins";


*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  // overscroll-behavior: none;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
  display: none;
}
::-webkit-scrollbar-thumb {
  background: #FF0000;
}

body {
  background: $color-black;
  overflow: hidden;
  height: 100vh;
  margin: 0 auto;


  &[data-scroll-locked] {
    padding-top: 0!important;
  }
}

#root {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

html {
  background: $color-bg;
  height: auto;
  overflow: hidden;
}


.container {
  padding-left: 20px;
  padding-right: 20px;
}

.titleMain {
  @include setFont(32px,600,$font-fam-outfit);
  color: $color-white;
  text-align: center;
}

.brightText {
  color: $color-yellow-bright
}