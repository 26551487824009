@import "../../../styles/mixins";


.homeWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  & * {
    @extend %no-user-select;
  }
}