@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.boostBuyModal {
  @include flexCol();
  flex-direction: column;

  &__img {
    width: 60px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__titleTxt {
    margin-top: 16px;
    margin-bottom: 16px;
    @include setFont(36px, 700, $font-fam-outfit);
    color: $color-white;
    text-align: center;
  }

  &__conentTxt {
    @include setFont(24px, 400, $font-fam-outfit);
    color: $color-white;
    text-align: center;
    margin-bottom: 16px;
  }

  &__rewardCoin {
    @include flexCol();
    gap: 8px;
  }

  &__rewardCoinImg {
    width: 18px;
  }

  &__rewardCoinTxt {
    @include setFont(12px, 600, $font-fam-outfit);
    color: $color-yellow-bright;
  }

  &__progressBlock {
    margin-top: 8px;
  }

  &__progressNumbers {
    @include flexCol(space-between);
    padding-bottom: 10px;
  }

  &__progressNumberTxt {
    @include setFont(16px, 400, $font-fam-outfit);
    color: $color-white;
  }

  &__buyBtn {
    margin-top: 16px;
  }

  &__btnCoinImg {
    width: 24px;
  }

  &__tooltipSuccess {
    background-color: rgba(157, 255, 0, 0.541);
  }
}
