@import "../../../styles/vars";
@import "../../../styles/mixins";


.earnHeader {
  @include flexCol();
  flex-direction: column;
  padding-bottom: 28px;

  &__img {
    width: 155px;
    aspect-ratio: 1/1;

    &_animating {
      animation: shuffle 0.3s linear;
    }
  }

}


@keyframes shuffle {
  0% {
    transform: translateX(0)
  }
  25% {
    transform: translateX(-10px)
  }
  50% {
    transform: translateX(10px)
  }
  75% {
    transform: translateX(-10px)
  }
  100% {
    transform: translateX(0)
  }
}