@import "../../../styles/vars";
@import "../../../styles/mixins";


.frensList {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
    padding-bottom: 8px;
  }

  &__inviteCoinsTxt {
    @include setFont(12px, 500, $font-fam-outfit);
    @extend %yellow-gradient-text;
  }
}
