@import "../../styles/vars";
@import "../../styles/mixins";


.qrScreen {
  width: 100%;
  height: 100%;
  @include flexCol();
  flex-direction: column;
  gap: 20px;

  &__title {
    @include setFont(32px, 600, $font-fam-outfit);
    color: $color-white;
  }

  &__main {
    border-radius: 12px;
    padding: 48px 48px 28px;
    background: #FEFEFE;
    @include flexCol();
    flex-direction: column;
    gap: 30px;
    max-width: 300px;
    width: 100%;
  }

  &__imgWrapper {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    transition: $transition;

    &_active {
      background: rgba(45, 27, 127, 0.44);

      &::before {
        content: "";
        position: absolute;
        width: 200%;
        height: 50px;
        transform: rotate(135deg) translateY(28%);
        top: 100px;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
        animation: loader 3s linear infinite;
      }
    }
  }

  &__img {
    width: 100%;
    aspect-ratio: 1/1;
    opacity: 0;
    transition: $transition;

    &_active {
      opacity: 1;
    }
  }

  &__botName {
    @include setFont(32px, 500, $font-fam-outfit);
    color: #2D1B7F;
  }
}


@keyframes loader {
  from {
    left: -400px
  }
  to {
    left: 300px
  }
}