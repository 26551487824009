@import "../../styles/vars";
@import "../../styles/mixins";

.loader {
  position: fixed; /* Зафиксированная позиция для покрытия всего экрана */
  z-index: 9999; /* Выше других элементов */
  top: 0;
  left: 0;
  width: 100%; /* Ширина на весь экран */
  height: 100vh; /* Высота на весь экран */
  background: $color-bg; /* Используем цвет фона из переменных */
  display: flex; /* Центрирование изображения */
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Предотвращаем прокрутку содержимого */

  &__image {
    width: 100%; /* Растягиваем на ширину экрана */
    height: 100%; /* Растягиваем на высоту экрана */
    object-fit: cover; /* Масштабируем, сохраняя пропорции */
  }

  // Убираем неиспользуемые круги и анимации
}
