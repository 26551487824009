@import "../../../styles/vars";
@import "../../../styles/mixins";

.achievmentsList {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &__title {
    @include setFont(18px, 600, $font-fam-outfit);
    color: $color-white;
    text-align: center;
    margin-bottom: 28px;
    margin-top: 10px;
  }
  &__main {
    padding: 14px 22px 16px;
    background: #1d1d21b2;
    border-radius: 16px 16px 0 0;
    border-top: 1px solid #f58136;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  &__cards {
    margin-bottom: 23px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;
    overflow-y: auto;
    width: 100%;
  }
  &__col {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  &__colHead {
    @include flexCol(space-between);
  }
  &__colTitle {
    @include setFont(14px, 700, $font-fam-outfit);
    color: $color-white;
  }
  &__cardsCount {
    @include setFont(14px, 700, $font-fam-outfit);
    color: $color-white;
    padding: 2px 4px;
    border: 1px solid $color-grey-light;
    border-radius: 4px;
  }
  &__cardsList {
    @include flexCol(flex-start);
    gap: 8px;
    width: 100%;
  }
  &__card {
    width: 62px;
    height: 62px;
    background: linear-gradient(180.75deg, #f58136 -10.01%, #272a2e 107.21%);
    border-radius: 12px;
    padding: 2px;
    @include flexCol();
    border: none;
    cursor: pointer;
  }

  &__cardInner {
    width: 60px;
    height: 60px;
    min-width: 60px;
    @include flexCol(space-between);
    flex-direction: column;
    gap: 3px;
    padding-top: 8px;
    padding-bottom: 2px;
    border-radius: 11px;
    background-color: $color-grey-bg;
  }

  &__cardImg {
    width: auto;
    max-width: 90%;
    height: 35px;
    object-fit: contain;

    &_disabled {
      filter: grayscale(1);
    }
  }
  &__cardTitle {
    @include setFont(10px, 500, $font-fam-outfit);
    color: $color-white;
    text-align: center;
  }
}
