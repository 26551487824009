@import "../../styles/vars";
@import "../../styles/mixins";


.tutorialSlider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #151F2B;
  display: flex;
  flex-direction: column;
  z-index: 50;

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 40%;
    @include bgWebp("../../assets/images/stars.png", "../../assets/images/stars.webp");
  }

  &__slider {
    width: 100%;
    flex: 1;
    height: calc(100vh - 115px);
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 50px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgb(21, 31, 43) 100%);

    }
  }

  &__slide {
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex!important;
    justify-content: space-between;
  }

  &__slideText {
    @include setFont(32px, 700, $font-fam-outfit);
    color: $color-white;
    text-align: center;
    margin-top: 50px;
    padding: 0 17px;
  }

  &__slideSubText {
    @include setFont(20px,500,$font-fam-outfit);
    color: $color-white;
    margin-top: 10px;
    text-align: center;
  }

  &__slideImg {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }

  &__footer {
    @include flexCol();
    padding: 17px 17px 47px;
  }

  &__skipBtn {
    padding: 16px 25px;
    border: 1px solid $color-yellow-bright;
    background: transparent;
    border-radius: 50px;
    cursor: pointer;
    @include setFont(16px, 600, $font-fam-outfit);
    color: $color-yellow-bright;
    transition: $transition;
    margin-right: 12px;
    max-width: 300px;

    &_hidden {
      max-width: 0px;
      overflow: hidden;
      padding: 0px;
      border-width: 0px;
      margin-right: 0px;
      opacity: 0;
    }
  }

  &__finishBtn {
    max-width: 500px;
    transition: $transition;


    &_finish {
      max-width: 250px;
    }
  }


}

.sliderDot {
  border-radius: 50%;
  box-shadow: 0 0 0 0px $color-white;
  width: 20px;
  height: 20px;
  @include flexCol();
  transition: $transition;


  &_active {
    box-shadow: 0 0 0 2px $color-white;
  }

  &__inner {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $color-grey-light;
    transition: $transition;

    &_active {
      background: $color-white;
    }
  }
}

:global(.slick-list) {
  height: 100%;
}

:global(.slick-slide > div) {
  height: 100%;
}

:global(.slick-track) {
  height: 100%;
}

:global(.slick-dots) {
  bottom: 35px!important;
}