@import "../../../styles/vars";
@import "../../../styles/mixins";

.progressbar {
  width: 100%;
  background: rgba(29, 53, 91, 0.5);
  border-radius: 20px;
  height: 10px;
  border: 1px solid rgba(29, 53, 91, 0.5);
  position: relative;

  &__inner {
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    transition: $transition;
  }

  &__thumb {
    width: 15px;
    height: 21px;
    background: #f48b01;
    border: 1px solid #5D687E;
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translate(-50%,-50%);
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
    transition: $transition;
  }

  &__gradient {
    width: calc(100vw - 40px);
    height: 10px;
    border-radius: 20px;
    background: linear-gradient(89.23deg, #eeb220 19.8%, #6923ff 50.04%);
  }
}
