@import "../../../styles/vars";
@import "../../../styles/mixins";


.leagueList {
  @include flexCol();
  flex-direction: column;
  gap: 20px;
  width: 100%;

  &__tabBar {
    width: 100%;
    background: $color-btn;
    border-radius: 24px;
    padding: 4px;
    @include flexCol();
    position: relative;
  }

  &__tab {
    flex: 1;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 4px;
    text-align: center;
    color: $color-white;
    @include setFont(18px, 700, $font-fam-sec);
    z-index: 2;
  }

  &__tabActiveBg {
    position: absolute;
    height: calc(100% - 8px);
    width: calc(50% - 8px);
    top: 4px;
    left: 4px;
    background: $color-yellow-gradient;
    border-radius: 24px;
    transform: translateX(0);
    transition: $transition;

    &_coins {
      transform: translateX(calc(100% + 8px));
    }

    &_pixels {
      transform: translateX(0%);
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding-bottom: 80px;
  }


  &__item_active {
    background: #3B3B54;
  }

  &__fixedItemWrapper {
    position: fixed;
    bottom: 80px;
    left: 2px;
    z-index: 9;
    width: calc(100% - 4px);
  }



  &__itemtopText {
    @include setFont(24px, 300, $font-fam-outfit);
    color: $color-grey-light;
  }
}
