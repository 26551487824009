@import "../../../styles/vars";
@import "../../../styles/mixins";

.customTooltip {
  position: fixed;
  width: calc(100% - 40px);
  left: 20px;
  top: 20px;
  background-color: $color-btn-active;
  padding: 8px 10px;
  border-radius: 12px;
  z-index: 9999999999999999999;
  transition: $transition;

  &__txt {
    @include setFont(12px, 400, $font-fam-outfit);
    color: $color-white;
    text-align: center;
  }
}
