@import "../../../styles/vars";
@import "../../../styles/mixins";


.frensHeader {
  @include flexCol();
  flex-direction: column;
  gap: 12px;
  padding-top: 32px;
  padding-bottom: 12px;

  &__img {
    width: 64px;
  }


  &__subtitle {
    @include setFont(16px, 400, $font-fam-outfit);
    @extend %yellow-gradient-text
  }

  &__coins {
    width: 100%;
    background: $color-btn;
    border-radius: 30px;
    padding: 12px;
    @include flexCol();
  }

  &__coinsTxt {
    @include setFont(18px, 700, $font-fam-outfit);
    color: $color-white;
  }
}
