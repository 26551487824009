// colors
$color-black: #000000;
$color-white: #ffffff;
$color-text-main: #faf4f2;
$color-black-bg: #1c1e23;
$color-grey-bg: #3b3f47;
$color-bg: radial-gradient(
  111.64% 111.64% at 50.13% 112.93%,
  #c26028 17.65%,
  #603014 45.6%,
  #000000 68.07%
);
$color-btn: #3b3b5480;
$color-btn-active: #3b3b54cc;
$color-grey: #85868e;
$color-grey-light: #a0a3a7;
$color-purple-light: #bcb8d9;
$color-yellow: #ffc107;
$color-yellow-bright: #ff9700;
$color-yellow-gradient: linear-gradient(
  90.92deg,
  #ffa800 2.12%,
  #f58136 99.21%
);

$font-fam-main: "Prompt", sans-serif;
$font-fam-sec: "Baloo 2", sans-serif;
$font-fam-outfit: "Outfit", sans-serif;

$transition: all 0.5s;
