@import "../../../styles/vars";
@import "../../../styles/mixins";


.frensInviteBtn {
  margin-bottom: 4px;
  margin-top: 8px;

  &__icon {
    width: 20px;
    height: 20px;
  }
}
