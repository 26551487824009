@import "../../../styles/vars";
@import "../../../styles/mixins";


.leagueWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}