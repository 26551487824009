@import "../../../styles/vars";
@import "../../../styles/mixins";

.homeInfo {
  @include flexCol();
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding-top: 8px;

  &__cityBlock {
    background-color: $color-btn;
    border-radius: 12px;
    padding: 8px 16px;
    @include flexCol(space-between);
    width: 100%;
  }

  &__cityName {
    @include flexCol();
    gap: 16px;
  }

  &__avatarImg {
    width: 36px;
    aspect-ratio: 1/1;
  }

  &__cityNameTxt {
    @include setFont(16px, 400);
    color: $color-text-main;
  }

  &__rank {
    @include flexCol();
    gap: 8px;
  }

  &__rankImg {
    width: 22px;
  }

  &__rankTxt {
    @include setFont(14px, 400);
    color: $color-text-main;
  }

  &__citySelect {
    border-radius: 12px;
    background-color: $color-btn;
    @include flexCol();
    padding: 8px 16px;
    width: 100%;
  }

  &__flex {
    flex: 1;
  }

  &__citySelectTxt {
    @include setFont(14px, 600);
    color: $color-text-main;
  }

  &__citySelectArrow {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }

  &__citySelectArrowIcon {
    @include icon(12px)
  }

  &__miningBlock {
    @include flexCol();
    gap: 3px;
    width: 100%;
  }

  &__miningBtn {
    background-color: $color-btn;
    @include flexCol();
    width: 100%;
    border-radius: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    gap: 12px;
  }

  &__miningCoinImg {
    @include icon(16px)
  }

  &__miningBtnTxt {
    @include setFont(14px, 400);
    color: $color-text-main;
  }


  &__sminingBtn {
    background-color: $color-btn;
    @include flexCol();
    width: 60%;
    border-radius: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    gap: 12px;
  }

  &__sminingCoinImg {
    @include icon(20px)
  }

  &__sminingBtnTxt {
    @include setFont(14px, 400);
    color: $color-text-main;
  }
}
