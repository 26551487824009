@import "../../../styles/vars";
@import "../../../styles/mixins";


.frensWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__main {
    height: 100%;
    overflow: auto;
    flex: 1;
  }
}