@import "../../styles/vars";
@import "../../styles/mixins";

.frenItem {
  width: 100%;
  background: $color-btn;
  border-radius: 12px;
  padding: 10px 16px;
  @include flexCol(space-between);
  gap: 16px;



  &__main {
    @include flexCol();
    gap: 16px;
  }

  &__coinImg {
    width: 36px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__nameTxt {
    @include setFont(16px, 400, $font-fam-outfit);
    color: $color-white;
  }

  &__coins {
    @include flexCol(flex-start);
    gap: 4px;
  }

  &__coinsImg {
    width: 15px;
  }

  &__coinsTxt {
    @include setFont(12px, 500, $font-fam-outfit);
    color: #EDDDDE;
  }
}
