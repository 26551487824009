@import "../../styles/vars";
@import "../../styles/mixins";


.errorScreen {
  width: 100%;
  height: 60%;
  @include flexCol(space-around);
  flex-direction: column;
  gap: 112px;

  &__txt {
    @include setFont(32px, 600, $font-fam-outfit);
    color: $color-white;
    text-align: center;
  }

  &__img {
    width: 200px;
    height: 200px;
  }
}
