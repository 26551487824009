@import "../../styles/vars";
@import "../../styles/mixins";


.dailyCheckin {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  width: 100%;
  height: 100%;
  padding: 10px 20px 40px;
  background: linear-gradient(180deg, #151F2B 70.85%, #6A5C88 100%);
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    @include bgWebp("../../assets/images/stars.png", "../../assets/images/stars.webp");
    width: 100%;
    height: 50%;
  }

  &__main {
    flex: 1;
    @include flexCol();
    flex-direction: column;
    gap: 9px;
  }

  &__coinImg {
    width: 150px;
    aspect-ratio: 1/1;
  }

  &__daysTxt {
    @include setFont(128px, 700, $font-fam-outfit);
    color: $color-white;
  }

  &__coinsTxt {
    @include setFont(32px, 700, $font-fam-outfit);
    color: $color-white;
    text-align: center;
  }

  &__footer {
    max-width: 250px;
    margin: 0 auto;
    @include flexCol();
    flex-direction: column;
    gap: 25px;
  }

  &__subTxt {
    @include setFont(20px, 500, $font-fam-outfit);
    color: $color-white;
    text-align: center;
  }
}

.dailyCheckin__coinImg_animating {
  animation: shake 0.3s ease;
}

@keyframes shake {
  0%, 100% {
      transform: translateX(0);
  }
  25% {
      transform: translateX(-5px);
  }
  50% {
      transform: translateX(5px);
  }
  75% {
      transform: translateX(-5px);
  }
}
