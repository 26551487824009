@import "../../../../styles/vars";
@import "../../../../styles/mixins";


.earnListItem {
  background: $color-btn;
  border-radius: 12px;
  padding: 8px 16px;
  @include flexCol(space-between);
  gap: 10px;
  cursor: pointer;
  border: none;
  cursor: pointer;
  transition: $transition;

  &_disabled {
    filter: brightness(0.5);
    cursor: default;
  }

  &__main {
    @include flexCol();
    gap: 18px;
  }

  &__icon {
    @include icon(32px)
  }

  &__texts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  &__nameTxt {
    @include setFont(16px, 400, $font-fam-outfit);
    color: $color-white
  }

  &__coins {
    @include flexCol();
    gap: 4px;
  }

  &__coinIconimg {
    width: 16px;
  }

  &__coinTxt {
    @include setFont(12px, 500, $font-fam-outfit);
    color: $color-white;
  }

  &__loader {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: $color-yellow;
    animation: loader 2s linear infinite;
  }

  &__statusIcon {
    @include icon(18px)
  }
}


@keyframes loader {
  from {transform: rotate(0deg)}
  to {transform: rotate(360deg)}
}