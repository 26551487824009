@import "../../../styles/vars";
@import "../../../styles/mixins";

.homeRocket {
  flex: 1;
  width: 100%;
  @include flexCol();
  position: relative;

  &__swipeArea {
    position: absolute;
    width: 100%;
    height: 80%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }

  &__btn {
    cursor: pointer;
    border: none;
    background: transparent;
    width: 100%;
    height: 100%;
  }

  &__img {
    width: 100%;
    aspect-ratio: 1 / 1;
    height: calc(100vh - 386px);
    object-fit: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: $transition;

    @include bgWebp("../../../assets/images/rocket.png", "../../../assets/images/rocket.webp");

    &_disabled {
      filter: grayscale(1);
    }
  }

  &__coinImage {
    position: absolute;
    @include flexCol();
    background-size: contain;
    background-repeat: no-repeat;
    animation: appearCoin 0.6s ease-in;
    z-index: 99;
  }

  &__starImg {
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    pointer-events: none;
    animation: lightning .5s linear forwards;
    @include bgWebp("../../../assets/icons/energyIcon.png", "../../../assets/icons/energyIcon.webp");
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__fire {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    filter: blur(5px);
    background: yellow;
    animation: fire .2s linear forwards;
    z-index: 9999;
  }
}

@keyframes appearCoin {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lightning {
  0% {
    opacity: 1;
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    opacity: 0;
    transform: translate(var(--x), var(--y)) rotate(360deg);
  }
}

@keyframes fire {
  0% {
    background: yellow;
    opacity: 1;
  }
  100% {
    background: red;
    opacity: 0;
  }
}
