@import "../../../styles/vars";
@import "../../../styles/mixins";


.errorFormModal {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;

  &__textblock {
    background: $color-btn-active;
    border-radius: 12px;
    padding: 24px 18px;
  }

  &__txt {
    @include setFont(24px, 400, $font-fam-outfit);
    color: $color-white;
    text-align: center;
  }
}
